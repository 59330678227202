const FRAGMENT_REGEX = /#(.*)/;

import { aemFallbackConfig, loader } from "maps-ui";

const attachMapsHook = ({ eventType, target, selector }) => {
  target.addEventListener(eventType, async (event) => {
    const { target } = event;

    if (!target instanceof HTMLElement || !target.matches(selector)) {
      return;
    }

    // We have a valid event, capture it
    event.preventDefault();
    event.stopPropagation();

    // pull the config from the href fragment
    const match = target.href.match(FRAGMENT_REGEX);

    if (!match) {
      throw new Error("Maps URL does not include a valid config fragment");
    }

    /*
    Since we needed to get super specific with our event listeners
    the default analytics will no longer fire, so we need to handle that
    */
    window.tcom_v2?.analytics?.fireWithContext?.("aa-link", target);

    const configId = match[1];
    const serviceUrlDefault =
      "/service/tcom/contentFragmentData/v2/defaultmaps/";

    const mapsContentFragmentExperience = `${serviceUrlDefault}${configId}`;

    try {
      const experienceResponse = await fetch(mapsContentFragmentExperience);
      const experienceData = await experienceResponse.json();

      const i18nPath = experienceData.i18n.split("/").pop();
      const i18nServiceUrl = `${serviceUrlDefault}${i18nPath}`;

      const i18nResponse = await fetch(i18nServiceUrl);
      const i18nData = await i18nResponse.json();

      // Close active hamburger menu
      const hamburger = document.getElementsByClassName("hamburger active");

      if (hamburger.length) {
        hamburger[0].click();
      }

      loader({ i18nContent: { ...experienceData, ...i18nData } });
    } catch (error) {
      console.error("Maps ~ error, using AEM fallback from maps-ui:", error);
      loader({ i18nContent: aemFallbackConfig.i18n });
    }
  });
};

const tcomHeader = document.getElementById("tcom-header");
attachMapsHook({
  eventType: "click",
  target: tcomHeader,
  selector: "a.maps-modal, .maps-modal a",
});

const tcomFooterLinks = document.querySelectorAll(".footer-links");
tcomFooterLinks.forEach((footerElement) => {
  attachMapsHook({
    eventType: "click",
    target: footerElement,
    selector: "a.maps-modal, .maps-modal > a",
  });
});

const tcomShoppingBelt = document.querySelectorAll(
  ".tcom-shopping-tools-panel"
);
tcomShoppingBelt.forEach((beltItem) => {
  attachMapsHook({
    eventType: "click",
    target: beltItem,
    selector: "a.maps-modal, .maps-modal > a",
  });
});
